@import "../fonts/nunito/nunito.css";

html,
body,
#root {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
}
